import { ref, computed, useHead } from "#imports"
import { defineNuxtPlugin } from "#app"
import Quasar from "quasar/src/vue-plugin.js"
import BottomSheet from "quasar/src/plugins/BottomSheet.js"
import Dialog from "quasar/src/plugins/Dialog.js"
import Loading from "quasar/src/plugins/Loading.js"
import LoadingBar from "quasar/src/plugins/LoadingBar.js"
import Notify from "quasar/src/plugins/Notify.js"
import lang from "quasar/lang/en-US"
import iconSet from "quasar/icon-set/material-icons"

export default defineNuxtPlugin((nuxt) => {




  nuxt.vueApp.use(Quasar, {
    lang,
    iconSet,
    plugins: {BottomSheet,Dialog,Loading,LoadingBar,Notify},
    
  })
})
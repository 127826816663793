export interface ISvgBarcode {
  id: number
  data: Element
  width: number
  height: number
}

export default defineNuxtPlugin(() => {
  const nuxt = useNuxtApp()

  return {
    provide: {
      getSvgBarcodes(doc: Document) {
        let svg = [] as ISvgBarcode[]
        doc.querySelectorAll('.forms-barcode').forEach(el => {
          svg.push({
            id: Number(el.getAttribute('data-id')),
            data: el,
            width: Number(el.getAttribute('width')!.replace('px', '')),
            height: Number(el.getAttribute('height')!.replace('px', '')),
          })
        })
        return svg
      },
    },
  }
})

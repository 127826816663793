export default defineNuxtPlugin(() => {
  const nuxt = useNuxtApp()
  const thousandSeparator = (nuxt.$i18n.locale.value == 'id' ? '.' : ',')
  const decimalSeparator = (nuxt.$i18n.locale.value == 'id' ? ',' : '.')

  return {
    provide: {
      toCamelCase(source: string): string {
        return source.replace(/-([a-z])/g, (g) => { return g[1].toUpperCase() })
      },
      toHyphenCase(source: string): string {
        return source.replace(/([a-z][A-Z])/g, (g) => { return `${g[0]}-${g[1].toLowerCase()}` })
      },
      formatPrice(value: number) {
        const val = (value / 1).toFixed(2).replace('.', ',') == 'NaN' ? 0 : (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      formatThousand(value: number) {
        if (isNaN(value))
          return (0).toFixed(2).replace('.', ',')
        const val = (value / 1)
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      toThousandFormatted(numericString: any) {
        let str = (typeof numericString === 'number') ? numericString.toString().replace('.', decimalSeparator) : numericString
        if (str) {
          const sign = (str.charAt(0) == '-')
          const val = str.replaceAll(thousandSeparator, '').replace('-', '').split(decimalSeparator).join('.')
          if ((!isNaN(Number(val)) && str.charAt(str.length - 1) == decimalSeparator)) {
            str = val.replace('.', '')
            str = (str.charAt(0) == '0') && (str.length > 1) ? str.substring(1, str.length) : str
            str = str ? str.split('').reverse().join('').match(/.{1,3}/g)!.join(thousandSeparator).split('').reverse().join('') : '0'
            str = sign ? '-' + str : str
            return str + decimalSeparator
          } else if (!isNaN(Number(val)) && (str.charAt(str.length - 1) !== decimalSeparator)) {
            const textArray = val.split('.')
            textArray[0] = (textArray[0].charAt(0) == '0') && (textArray[0].length > 1) ? textArray[0].substring(1, textArray[0].length) : textArray[0]
            textArray[0] = textArray[0] ? textArray[0].split('').reverse().join('').match(/.{1,3}/g)!.join(thousandSeparator).split('').reverse().join('') : '0'
            str = !isNaN(Number(textArray[1])) ? textArray.join(decimalSeparator) : textArray[0]
            str = sign ? '-' + str : str
            return str
          } else {
            return str
          }
        }
      },
      toNumber(numericString: any) {
        if (typeof numericString === 'string') {
          return numericString ? Number(numericString.replaceAll(thousandSeparator, '').replaceAll(decimalSeparator, '.')) : ''
        } else if (typeof numericString === 'number') {
          return numericString
        }
      },
      getThousandSeparator() {
        return thousandSeparator
      },
      getDecimalSeparator() {
        return decimalSeparator
      },
      toDate(str: string) {
        const dateObj = new Date(str)
        return str ? [String(dateObj.getUTCDate()).padStart(2, '0'), String(dateObj.getUTCMonth() + 1).padStart(2, '0'), dateObj.getUTCFullYear()].join('-') : ''
      },
      toMonth(str: string) {
        const dateObj = new Date(str)
        return str ? [dateObj.getUTCFullYear(), String(dateObj.getUTCMonth() + 1).padStart(2, '0')].join('-') : ''
      },
      formatDate(value: string, type = 'dateTime') {
        if (value == null) return ''
        // Step 1: Convert date string to JavaScript Date object
        const jsDate = new Date(value)

        // Step 2: Define the desired date format (you can customize this format)
        let options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          locale: 'id-ID',
        }

        if (type === 'day') {
          options = {
            weekday: 'long', // Tambahkan nama hari
          };
        } else if (type == 'month') {
          options = {
            year: 'numeric',
            month: 'long',
          }
        }

        // Step 3: Format the JavaScript Date object to the desired format
        const formattedDate = jsDate.toLocaleDateString('id-ID', options)

        if (formattedDate.includes('pukul')) {
          if (type === 'dateTime') {
            return formattedDate.replace('pukul ', '')
          } else if (type === 'date') {
            return formattedDate.split(' pukul ')[0]
          } else if (type === 'time') {
            return formattedDate.split(' pukul ')[1]
          }
        } else {
          return formattedDate
        }
      },
      getDatetime(type: string = 'datetime') {
        const dateObj = new Date()
        const year = dateObj.getUTCFullYear()
        const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
        const day = String(dateObj.getUTCDate()).padStart(2, '0')
        const dateNow = year + '-' + month + '-' + day
        if (type == 'date') {
          return dateNow
        }
        const hours = String(dateObj.getUTCHours()).padStart(2, '0');
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const seconds = String(dateObj.getUTCSeconds()).padStart(2, '0');
        const timeNow = hours + ':' + minutes + ':' + seconds;
        if (type == 'time') {
          return timeNow
        }
        if (type == 'hourMinute') {
          return hours + ':' + minutes
        }
        return `${dateNow} ${timeNow}`
      },
      formatBoolean(value: number) {
        return (value === 1 ? nuxt.$getTranslation('shared.buttons.yes') : nuxt.$getTranslation('shared.buttons.no')).toUpperCase()
      },
      formatDate2(dateString: string): string {
        const dateObj = new Date(dateString)

        const year = dateObj.getUTCFullYear()
        const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
        const day = String(dateObj.getUTCDate()).padStart(2, '0')
        return `${day}/${month}/${year}`
      },
      padNumberWithZeros(number: number, length: number = 5) {
        // Convert the number to a string
        let numberStr = number.toString();

        // Calculate the number of zeros to pad with
        let zerosToPad = length - numberStr.length;

        // Create the padded string with leading zeros
        let paddedString = '0'.repeat(zerosToPad) + numberStr;

        return paddedString;
      },
      taxPercentage(): number | undefined {
        const { data } = useAuth()
        const route = useRoute()
        if (route.params.store_id) {
          const activeStore = data.value!.stores.find(store => store.id === parseInt(route.params.store_id.toString()))
          if (activeStore) {
            return activeStore.tax_percentage;
          } else {
            return 0;
          }
        }
      },
      expeditionTaxPercentage(): number | undefined {
        const { data } = useAuth()
        const route = useRoute()
        if (route.params.store_id) {
          const activeStore = data.value!.stores.find(store => store.id === parseInt(route.params.store_id.toString()))
          if (activeStore) {
            return activeStore.expedition_tax_percentage;
          } else {
            return 0;
          }
        }
      },
      pointToRupiah(): number | undefined {
        const { data } = useAuth()
        const route = useRoute()
        if (route.params.store_id) {
          const activeStore = data.value!.stores.find(store => store.id === parseInt(route.params.store_id.toString()))
          if (activeStore) {
            return activeStore.point_to_rupiah;
          } else {
            return 0;
          }
        }
      },
      rupiahToPoint(): number | undefined {
        const { data } = useAuth()
        const route = useRoute()
        if (route.params.store_id) {
          const activeStore = data.value!.stores.find(store => store.id === parseInt(route.params.store_id.toString()))
          if (activeStore) {
            return activeStore.rupiah_to_point;
          } else {
            return 0;
          }
        }
      },
      darkMode(): boolean {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          return true
        }
        return false
      },
      formatMinutesToDays(minutes: number): string {
        const minutesInADay = 24 * 60; // 24 hours * 60 minutes
        const days = Math.floor(minutes / minutesInADay);
        return `${days}`;
      }
    },
  }
})
